import { useMemo } from "react"
import memoize from "lodash/memoize"
import Typograf from "typograf"

const typograf = new Typograf({ locale: ["ru", "en-US"] })
typograf.enableRule("common/nbsp/afterShortWord")
typograf.disableRule("common/space/trimRight")
typograf.disableRule("common/space/trimLeft")
typograf.enableRule("ru/money/ruble")

export const useTypograf = (text) =>
  useMemo(() => typograf.execute(text), [text])

export default memoize((text) => {
  return typograf.execute(text)
})
